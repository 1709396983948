<template>
  <div class="bg-white project-overview-container h-100">
    <div class="main-container h-100">
      <v-overlay :value="loading" z-index=8 >
      <div class="loading-template"> Please wait while we load the data... </div>
    </v-overlay>
      <template v-if="!loading">
        <v-row dense class=" pa-0 ma-0 mobbile-padding">
          <v-col cols="12" class="pa-0">
            <span class="d-flex card-title pa-0 edit-height">
              <v-btn v-if="$route.name === 'My Content Plan' || $route.name === 'New Content Plan' "
                text
                :to="{ name: 'Content History'}"
                class="pl-0 mb-1 sweeps-back-btn"
              >
                <v-icon class="icon-width"> mdi-chevron-left </v-icon>
                <span class="navigation-text">Back to Content History</span>
              </v-btn>
            </span>
            <v-row class="pa-0 ma-0" v-if="isEditable">
              <v-col class="d-flex justify-end pa-0 edit-height" v-if="roleChecking">
                <v-btn
                  text
                  class="pa-0 pb-1"
                  color="black lighten-2"
                  @click="openForm"
                >
                  <img src="@/assets/svg/theme/edit.svg"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-4 pb-0" :class="isEditable ? 'py-2' : 'py-4'">
                <div class="card-content-title name pl-0 notranslate">
                  {{ contentKits.name }}
                  <span class='text-info'>{{ contentKits.isDraft ? '(draft)' : '' }} </span>
                </div>
              </v-col>
            </v-row>
            <v-card-text class="pa-0" style="overflow: hidden;">
              <span class="d-block pl-2 pb-3 description">
                {{ contentKits.description }}
              </span>
            </v-card-text>
          </v-col>
          <v-col class="py-1" v-if="contentKitId">
          <OrderSummary
            :kit="contentKits"
            :blend="subscriptionBlend"
          />
          </v-col>
          <!--<v-col cols="12" class="pa-2 px-1">
            <v-card class="b-1 pl-1" elevation="0">
              <v-row class="ma-0 pa-0">
                <v-col cols="8" class="pa-0">
                  <v-card-title class="card-title pb-0">
                    CONTENT STRATEGY
                  </v-card-title>
              </v-col>
              <v-col cols="4" class="d-flex justify-end pa-0 pr-4 pb-2 pt-3 edit-height"
              v-if="roleChecking">
                  <v-btn
                    v-if="mode.goal !== 'normal'"
                    text
                    class="pa-0 pr-3 pb-1"
                    color="black lighten-2"
                    @click="toggleCancel('goal')"
                  >
                    <span class="pl-3">Cancel</span>
                  </v-btn>
                  <v-btn
                    text
                    class="pa-0 pb-1"
                    color="black lighten-2"
                    @click="toggleMode('goal')"
                  >
                    <img
                      v-if="mode.goal === 'normal'" class="pt-2 pl-2"
                      src="@/assets/svg/theme/edit.svg"
                    />
                    <span v-else class="save-btn pa-3">Save</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="px-5 pa-0">
                <v-col cols="12" class="pa-0 pl-4 pr-5">
                  <BaseInput
                  outlined
                  :mode="mode.goal"
                  :placeholder="'Goal Name'"
                  :name="'goalName'"
                  v-model.trim="profile.name"
                  class="pt-2 mb-n1"
                >
                  <div class="card-subtitle name">{{ profile.name }}</div>
                </BaseInput>
                </v-col>
              </v-row>
              <v-card-text class=" pt-1 pr-7 pl-2" style="overflow: hidden">
                <BaseTextAreaField
                  filled
                  rows="3"
                  class="bio-text mb-n5 pl-3"
                  :mode="mode.goal"
                  placeholder="Description"
                  name="text"
                  v-model.trim="profile.bio"
                >
                  <span class="d-block pb-4 pt-2" style="padding-left: 4px">
                    {{ profile.bio }}
                  </span>
                </BaseTextAreaField>
              </v-card-text>
            </v-card>
          </v-col> -->
          <v-col cols="12" class="pa-2 px-1">
            <v-card class="b-1 pa-1" elevation="0">
              <v-row class="pa-0 ma-0">
                <v-card-title class="card-title px-3 py-2 mobile-pl-2">
                  DELIVERABLES
                </v-card-title>
                <v-col class="d-flex justify-end pa-2 pb-0" v-if="roleChecking && contentKits.id">
                  <div v-if="isEditable">
                    <div v-if="['organization', 'org/business'].includes(page)">
                      <v-btn text class="my-content-plan pa-2"
                      color="info"
                      v-if="contentKitId && deliverables.length === 0"
                      @click="$emit('step-change', 3)">
                        + New
                      </v-btn>
                      <v-btn
                          text
                          icon
                          color="black lighten-2"
                          v-if="deliverables.length > 0"
                          @click="$emit('step-change', 3)"
                        >
                          <img src="@/assets/svg/theme/edit.svg" />
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-btn text class="my-content-plan pa-2"
                      color="info"
                        :to="{ name: 'Edit Deliverables',
                        params: { contentKitId: this.contentKitId}}"
                        v-if="contentKitId && deliverables.length === 0">
                          + New
                      </v-btn>
                      <v-btn
                          text
                          icon
                          color="black lighten-2"
                          :to="{ name: 'Edit Deliverables'}"
                          v-if="deliverables.length > 0"
                        >
                          <img src="@/assets/svg/theme/edit.svg" />
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-card-subtitle class="pa-6 pt-0 mobile-pl-4" v-if="deliverables.length">
                <v-simple-table fixed-header height="200px" class="table-height">
                  <template v-slot:default >
                    <thead>
                      <tr>
                        <th
                          v-for="item in headers"
                          :key="item.deliverable"
                          class="px-0 pb-2 pt-0"
                          :class="item.text === 'Credit Cost'
                          ? 'text-center cost-width' : item.text === 'Dimensions'
                          ? 'text-left pl-1 export-width' : item.text === 'Deliverable'
                          || item.text === 'Deliverable Type' ? 'text-left pl-1 cost-width'
                          : 'text-left pl-2 cost-width'"
                        >
                          <span class="name-ellipsis">{{ item.text }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in deliverables" :key="item.id">
                        <td class="text-left pa-1 ">{{ item.deliverable.name }}</td>
                        <td class="text-left pa-1">{{ categoryName(item) }}</td>
                        <td>
                          <v-menu :close-on-content-click="false" offset-x>
                            <template v-slot:activator="{ attrs, on }">
                              <div v-if="!isEditable && item.dimensions.length > 2">
                                  <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                      <span
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        <span v-if="item.dimensions.length"
                                          class="pr-1 social-name">
                                          {{ selectedDimesionExports(item.dimensions) }}
                                        </span>
                                     </span>
                                    </template>
                                    <v-col class="py-0 tool"
                                        cols=12 v-for="id in item.dimensions"
                                        :key="id.id"
                                      >
                                        {{id.dimension.name}}
                                      </v-col>
                                  </v-tooltip>
                                </div>
                              <div v-else>
                                <span
                                v-if="item.dimensions.length && !isEditable && !roleChecking"
                                class="pr-1 social-name">
                                  {{ selectedDimesionExports(item.dimensions) }}
                                </span>
                                <span
                                v-if="item.dimensions.length && isEditable && roleChecking"
                                v-bind="attrs" v-on="on"
                                class="pr-1 social-name cursor-pointer"
                                @click="getselectedDimension(item.dimensions)">
                                  {{ selectedDimesionExports(item.dimensions) }}
                                </span>
                                <span
                                v-else-if="isEditable && roleChecking"
                                class="cursor-pointer"
                                v-bind="attrs" v-on="on"
                                @click="getselectedDimension(item.dimensions)">Select Dimensions
                                </span>
                                <v-icon class="pa-0"
                                  @click="getselectedDimension(item.dimensions)"
                                  v-if="isEditable && roleChecking"
                                  v-bind="attrs" v-on="on">
                                  mdi-chevron-down
                                </v-icon>
                              </div>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="dimension in dimensions"
                                :key="dimension.id"
                                link
                              >
                                <v-list-item-title>
                                  <v-checkbox
                                    class="selected-items"
                                    :label="dimension.name"
                                    :value="dimension.id"
                                    v-model="selected"
                                    multiple
                                    @change="onClick(item.id, dimension.id)"
                                  ></v-checkbox>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                        <td class="text-left pl-7">
                        {{ item.quantity }}</td>
                        <td class="text-left pa-1">
                          <div v-if="isEditable">
                                <v-select
                                  class="status-select notranslate"
                                  :items="deliverablesStatuses"
                                  solo
                                  flat
                                  dense
                                  item-value="value"
                                  item-text="label"
                                  :hide-details="true"
                                  v-model="item.status"
                                  @change="updateDeliverableStatus( item.id, $event)"
                                  :menu-props="{'content-class' : 'skillset-dropdown notranslate'}"
                                  :disabled="isEditable ? false : true"
                              >
                              </v-select>
                          </div>
                              <div class="text-left pa-1" v-else>
                                {{ deliverablesStatus(item.status) }}
                              </div>
                              </td>
                        <td class="text-center">
                          {{ creditQuantity(item.deliverable.creditCost, item.quantity) }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5" class="text-left pa-1 total-text">Total</td>
                        <td class="text-center pa-1 total-text">
                          {{contentKits.creditsUsed}}/{{ totalCredit }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-subtitle>
              <v-card-subtitle v-else>
               <span class="d-flex justify-center no-plans">No deliverables</span>
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="isEditable">
        <v-card-actions class="d-flex justify-end pa-0 pr-7 pt-3 mobile-button-padding"
        v-if="roleChecking">
          <v-btn
            elevation="0"
            class="pa-3"
            text
            color="primaryGray1"
            @click="toggleCancel"
            v-if="contentKitId && contentKits.isDraft"
          >
            Cancel
          </v-btn>
          <v-btn
          v-if="contentKitId && contentKits.isDraft"
          class="pa-2 ml-4 btn-purple" @click="submit">Submit
          </v-btn>
        </v-card-actions>
        </div>
          <v-container
            fluid
            v-if="showContentplanForm"
          >
            <Modal
              persistent
              content-class="new-user-form"
              :modal="showContentplanForm"
              scrollable
            >
            <ContentPlanForm
                @close-modal="closeForm"
                :kit="contentKits"
            />
            </Modal>
        </v-container>
        <template v-if="showConfirm">
          <v-dialog v-model="showConfirm" max-width="600">
            <v-card class="py-4" flat>
              <v-card-title class="black--text">
                Please save current changes before editing another section.
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="info"
                  @click="showConfirm = false"
                  class="py-0"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-if="confirmRollover">
          <v-dialog v-model="confirmRollover" max-width="600">
            <v-card class="pa-4" flat>
              <div class="d-flex justify-end">
                <v-icon class="pa-0 icon-height" @click="confirmRollover = false">
                  mdi-close
                </v-icon>
              </div>
              <v-card-title class="black--text credits-heading pt-0">
               Apply Credits to Next Content Plan?
              </v-card-title>
              <v-card-text class="rollover-credits pt-4 pb-2">
                You used <span class="underline">
                {{contentKits.creditsUsed
                ? contentKits.creditsUsed : 0}}/{{totalCredit}}
                </span> Credits.
              </v-card-text>
               <v-card-text class="rollover-credits">
                Apply {{totalCredit - contentKits.creditsUsed}}
                credits to the next Content Plan?
              </v-card-text>
                <v-row class="ma-0">
                  <v-col cols="12" class="d-flex justify-end pa-0">
                      <v-btn text
                        @click="confirmRollover = false"
                        class="pa-0 cancel-btn"
                        color="primaryGray1"
                      >
                        Cancel
                      </v-btn>
                      <v-btn text
                        type="submit"
                        class="ml-4 pa-0"
                        color="info"
                        @click="rolloverCredits()"
                      >
                        Yes
                      </v-btn>
                  </v-col>
                </v-row>
            </v-card>
          </v-dialog>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Modal from '@/components/common/Modal';
import ContentPlanForm from '@/components/dashboard/screens/common/ContentPlanForm';
import OrderSummary from '@/components/dashboard/screens/common/OrderSummary';

export default {
  name: 'ContentPlan',
  components: {
    OrderSummary,
    Modal,
    ContentPlanForm,
  },
  props: {
    page: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('contentKit', ['contentKits', 'dimensions']),
    ...mapGetters('project', ['currentProject']),
    categoryName() {
      return (item) => _.get(item.deliverable, 'category.name');
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    deliverablesStatus() {
      return (status) => {
        if (status === '1') {
          return 'In Progress';
        }
        return 'Completed';
      };
    },
    roleChecking() {
      if (this.role === 'executive' || this.role === 'producer') {
        return true;
      }
      return false;
    },
    contentKitId() {
      return this.$route.params.contentKitId || this.$route.query.contentKitId;
    },
    deliverables() {
      const projectDeliverable = _.get(this.contentKits, 'projectDeliverables', []);
      const deliverable = _.map(projectDeliverable, (item) => item);
      return deliverable;
    },
    totalCredit() {
      const credits = this.contentKits.totalCredits + this.contentKits.extraCredits;
      return credits;
    },
    selectedDimesionExports() {
      return (items) => {
        const platforms = items.slice(0, 2);
        let val = _.map(platforms, 'dimension.name').join(', ');
        if (items.length > 2) {
          val += ' ...';
        } else if (items.length === 0) {
          return 'Select Dimensions';
        }
        return val;
      };
    },
    creditQuantity() {
      return (cost, quantity) => cost * quantity;
    },
    canUpdate() {
      return _.includes(['admin', 'producer', 'executive'], this.role);
    },
    isEditable() {
      const team = _.get(this.currentProject, 'business.businessTeam', []);
      const assigned = !!team.find((x) => `${x.userId}` === `${this.userDetails.id}`);
      return this.canUpdate && assigned && this.currentProject.status !== '2';
    },
    subscriptionBlend() {
      if (this.contentKitId) {
        const project = _.get(this.contentKits, 'project', {});
        if (project) {
          return {
            name: project.business.subscriptionBlends[0].name,
            access: project.business.subscriptionBlends[0].access,
          };
        }
      }
      return {};
    },
  },
  data() {
    return {
      selected: [],
      loading: true,
      showContentplanForm: false,
      confirmRollover: false,
      // profile: {
      //   name: 'Growing',
      //   bio:
      //     'Lorem ipsum dolor sit amet consectetur adipisicing elit.
      // Aliquam rem nobis maxime dolores? Hic aperiam mollitia minus. Eligendi maiores magnam,
      // est dolore temporibus quasi ipsam ut, porro illo, vel corrupti.',
      // },
      // mode: {
      //   goal: 'normal',
      // },
      showConfirm: false,
      headers: [
        {
          text: 'Deliverable',
          value: 'deliverable',
        },
        {
          text: 'Deliverable Type',
          value: 'type',
        },
        {
          text: 'Dimensions',
          value: 'exports',
        },
        {
          text: 'Quantity',
          value: 'quantity',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Credit Cost',
          value: 'creditcost',
        },
      ],
      deliverablesStatuses: [
        { label: 'In Progress', value: '1' },
        { label: 'Completed', value: '2' },
      ],
    };
  },
  methods: {
    ...mapActions('contentKit', ['getContentKit', 'deliverableStatus', 'updateContentKit', 'getDimensions', 'addDimension', 'removeDimension']),
    openForm() {
      this.showContentplanForm = true;
    },
    async closeForm(success = false) {
      this.showContentplanForm = false;
      if (success) {
        await this.getContentKit(this.contentKitId);
      }
    },
    toggleCancel() {
      const project = this.currentProject;
      const businessId = project.sharedWithBusinessId || project.businessId;
      let url = `/project_overview/${this.$route.params.projectId}/content_plan_history`;
      url += `?businessId=${businessId}`;
      this.$router.push(url);
    },
    async onClick(deliverableId, dimensionId) {
      const tags = this.selected.includes(dimensionId) ? await this.addDimension({
        projectDeliverableId: deliverableId,
        dimensionId,
      }) : await this.removeDimension({
        projectDeliverableId: deliverableId,
        dimensionId,
      });
      await this.getContentKit(this.contentKitId);
      return tags;
    },
    updateDeliverableStatus(deliverableId, e) {
      this.deliverableStatus({ projectDeliverableId: deliverableId, status: e });
    },
    submit() {
      if (this.contentKits.creditsUsed < this.totalCredit) {
        this.confirmRollover = true;
      } else {
        this.confirmRollover = false;
        this.toggleMode(false);
      }
    },
    rolloverCredits() {
      this.confirmRollover = false;
      this.toggleMode(false);
    },
    getselectedDimension(dimensions) {
      const dimensionsDataArr = [];
      _.map(dimensions, (dimension) => {
        dimensionsDataArr.push(dimension.dimensionId);
      });
      this.selected = dimensionsDataArr;
    },
    async toggleMode(draftBool) {
      const project = this.currentProject;
      const businessId = project.sharedWithBusinessId || project.businessId;
      const result = await this.updateContentKit({
        id: this.contentKitId,
        payload: Object.assign({}, { ...this.contentKits },
          {
            projectId: this.$route.params.projectId,
            id: this.contentKitId,
            isDraft: draftBool,
          }),
      });
      if (result.success) {
        if (['organization', 'org/business'].includes(this.page)) {
          this.$emit('step-change', 1);
        } else {
          let url = `/project_overview/${this.$route.params.projectId}/content_plan_history`;
          url += `?businessId=${businessId}`;
          this.$router.push(url);
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.contentKitId) {
      await this.getContentKit(this.contentKitId);
    }
    await this.getDimensions();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.b-1 {
  border: solid 1px #d8d8d8;
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
    border-radius: 3px !important;
}
.v-list-item--two-line {
  min-height: 50px;
}
::v-deep .v-data-table td[data-v-0e8af5f8] {
  padding-right: 4px !important;
}
::v-deep .v-select__selection {
  width: 90%;
  font-size: 14px;
}
.card-title {
  color: #727272 !important;
  font-size: 12px;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
}
.card-content-title {
  color: #1b1c1e !important;
  font-size: 24px;
  font-family: $fontFamily1;
  font-weight: normal;
}
.table-height {
  overflow: auto;
}
.card-subtitle {
  color: #1b1c1e;
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
}
.name {
  width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  word-break: break-all
}
.underline {
  text-decoration: underline;
}
.bg-white {
  background-color: $neutral6;
  height: 100%;
}
.bio-text {
  color: #1b1c1e;
  font-family: $fontFamily1;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}
.save-btn {
  color: $secondary1;
}
.item-tags{
  letter-spacing: -0.09px !important;
  font-size: 14px !important;
  text-align: left;
  font-family: $fontFamily1 !important;
  font-weight: normal;
}
.navigation-text {
   color: #727272;
  font-size: 14px !important;
  font-family: $fontFamily1;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 44px;
}
.credit-heading {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: 600;
}
.icon-height {
  height: 15px;
}
.rollover-credits {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.credit-heading {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b1c1e;
}
.cancel-btn {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.cost-width {
  width: 15% !important;
}
.social-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.description {
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  color: #1b1c1e;
}
.name-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
}
.v-data-table--fixed-header thead th {
  width: 18%;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;;
  color: #1b1c1e !important;
  border-bottom: 1px solid $neutral7!important;
}
.v-data-table th {
  height: 20px;
}
.export-width {
  width: 15% !important;
}
.v-data-table td {
  padding: 0px;
  padding-right: 16px !important;
  padding-left: 4px;
  font-family: $fontFamily1;
  font-size: 14px;
  font-weight: normal;;
  color: #1b1c1e;
}
.inprogress {
  color: #727272 !important;
}
.total-text {
  font-weight: 600 !important;
}
.save-draft {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
}
.submit {
  font-family: $fontFamily1;
  font-size: 18px;
  text-align: center;
}
.selected-items {
  .v-label{
    color: $charcoalBlack;
    font-size: 14px;
    font-family: $fontFamily1;
    font-weight: normal;
    text-align: left;
  }
}
.my-content-plan {
  font-family: $fontFamily1;
  font-weight: normal;
  text-align: left;
  ::v-deep .v-btn__content {
    font-size: 14px;
    line-height: 1.38;
    letter-spacing: -0.38px;
  }
}
.edit-height {
  height: 15px;
}
::v-deep .v-btn > .v-btn__content .v-icon {
  width: 16px;
}
::v-deep .v-text-field--outlined .v-label {
  top: 10px;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot{
    border-color: $neutral10 !important;
    min-height: 40px;
    margin: 0 0 3px 0;
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label{
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    color: $charcoalBlack;
  }
.v-input--selection-controls.v-input {
  height: 24px;
}
::v-deep .v-input--selection-controls__ripple {
    height: 18px;
    left: -4px;
    top: calc(50% - 16px);
    width: 18px;
}
.v-list-item {
  min-height: 38px;
}

.text-info{
  color: $primary1;
}

@media (max-width: 600px) {
  .mobbile-padding {
    padding-top: 24px !important;
    padding-right: 24px !important;
    padding-left: 20px !important;
  }
  .mobile-button-padding {
    padding-right: 20px !important;
    padding-bottom: 20px !important;
  }
  .mobile-pl-2 {
    padding-left: 8px !important;
  }
  .mobile-pl-4 {
   padding-left: 16px !important;
  }
}
</style>
